import React, { useState } from 'react';
// Components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
// Icons
import { Edit, PermIdentity, Close, Check } from "@material-ui/icons";
// MobX
import { inject, observer } from 'mobx-react';
// Styles
import styles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { CircularProgress, Divider, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
// Dayjs
import dayjs from 'dayjs';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_ACCOUNT } from 'mutations/account';
import { getLSValue } from 'utils/localStorage';
import { setLSValue } from 'utils/localStorage';
import { REFRESH_TOKEN } from 'queries';

const useStyles = makeStyles(theme => ({
  ...styles,
  subtitle: {
    color: '#999',
    fontWeight: 'bolder',
    fontSize: '.935rem'
  },
  containerWrapper: {
    margin: '20px 0'
  },
  subtitleText: {
    color: '#000'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  button: {
    padding: '5px 15px',
    zIndex: '10'
  },
  inputsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  spinner: {
    '& > svg': {
      margin: '0px',
      width: '20px',
      height: '20px'
    }
  },
  buttonText: {
    marginLeft: '5px'
  },
  formControl: {
    marginBottom: '0',
    paddingTop: '0'
  },
  marginRight: {
    marginRight: '10px'
  },
  buttonsWrapper: {
    display: 'flex',
    [theme.breakpoints.down("sm")]: {
      marginTop: '.75rem',
      flexDirection: 'column'
    }
  }
}));

const AboutProfileCard = ({ session, readonly }) => {
  const { validTo, user, activeAccount: { FirstName, LastName, Company, Street, ZIP, City, Country, email } } = session;
  const date1 = dayjs(validTo).endOf("d");
  const [editMode, setEditMode] = useState(false);
  const [refreshToken, { loading: refreshTokenLoading, error: refreshTokenError }] = useLazyQuery(REFRESH_TOKEN, {
    onCompleted: data => accountUpdatedHandler(data),
    fetchPolicy: 'no-cache'
  });
  const [updateAccount, { loading, error: updateAccountError }] = useMutation(UPDATE_ACCOUNT, {
    onCompleted: data => {
      if (data.updateAccount.response) refreshToken();
    },
    fetchPolicy: 'no-cache'
  });
  const { t } = useTranslation();
  const classes = useStyles();
  const [formValues, setFormValues] = useState({
    FirstName,
    LastName,
    Company,
    Street,
    ZIP,
    City,
    Country
  });
  const [error, setError] = useState('');

  const inputHandler = event => {
    event.persist();

    setFormValues((prevFormState) => ({
      ...prevFormState,
      [event.target.id]: event.target.value
    }));
  }

  const onSubmit = () => {
    const id = session.user.activeAccount.Id;
    updateAccount({ variables: { id, input: formValues } });
  }

  const accountUpdatedHandler = data => {
    setEditMode(false);

    if (data.refreshToken.tokens) {
      updateLocalStorageTokens(data.refreshToken);
    }
  }

  const updateLocalStorageTokens = ({ tokens }) => {

    const newToken = tokens.find( t => t.accountPos === session.user.activeAccount.Id ).token;
    setLSValue("token", newToken);
    const oldTokens = getLSValue("tokens");
    session.saveToken(newToken);

    if (oldTokens) {
      setLSValue("tokens", JSON.stringify(tokens));
    }
  };

  const renderRemainingTime = () => {
    if (date1.format("MM/DD/YYYY") !== dayjs().format("MM/DD/YYYY")) {
      return `${date1.diff(dayjs(), 'day')} ${t('days')} ${t('left')}`;
    } else {
      const hours = date1.diff(dayjs(), "hours");
      const minutes = hours < 1 ? date1.diff(dayjs(), "minute") : '';
      return `${hours}h ${minutes ? `${minutes}m` : ''} ${t('left')}`;
    }
  }

  return (
    <GridItem xs={12} sm={12} md={8}>
      <Card>
        <CardHeader color="rose" icon>
          <CardIcon color="rose">
            <PermIdentity />
          </CardIcon>
          <div className={classes.header}>
            <h3 className={classes.cardIconTitle} style={{ lineHeight: '20px', marginRight: '.5rem' }}>{t('About account')}</h3>
            {!readonly && (
              <div className={classes.buttonsWrapper}>
                {!editMode && (
                  <Button justIcon color="rose" className={classes.button} onClick={() => setEditMode((prevState) => !prevState)}>
                    <Edit style={{ margin: "0" }} />
                  </Button>
                )}
                {editMode && (
                  <>
                    <Button color="success" className={classes.button} onClick={onSubmit} disabled={loading}>
                      <div>
                        {
                          loading
                            ? <CircularProgress className={classes.spinner} size={20} />
                            : <Check className={classes.icon} />
                        }
                      </div>
                      <div className={classes.buttonText}>{t("Save")}</div>
                    </Button>
                    <Button color="danger" className={classes.button} onClick={() => setEditMode(false)} disabled={loading}>
                      <Close className={classes.icon} />
                      <div className={classes.buttonText}>{t("Cancel")}</div>
                    </Button>
                  </>
                )}
              </div>
            )}
          </div>
        </CardHeader>
        <CardBody>
          <GridContainer className={classes.containerWrapper}>
            <GridItem xs={12} sm={4} md={4} style={{ marginTop: '1rem' }}>
              <p className={classes.subtitle}>{t('Full name')}</p>
              <div className={classes.subtitleText}>
                {editMode
                  ? (
                    <div className={classes.inputsWrapper}>
                      <CustomInput
                        labelText={t('First Name')}
                        id="FirstName"
                        formControlProps={{ fullWidth: true, className: classes.formControl }}
                        labelProps={{ style: { position: 'unset', top: 0 } }}
                        inputProps={{
                          value: formValues.FirstName || '',
                          onChange: inputHandler,
                          style: { marginRight: '5px' }
                        }}
                      />

                      <CustomInput
                        labelText={t('Last Name')}
                        id="LastName"
                        formControlProps={{ fullWidth: true, className: classes.formControl }}
                        labelProps={{ style: { position: 'unset', top: 0 } }}
                        inputProps={{
                          value: formValues.LastName || '',
                          onChange: inputHandler
                        }}
                      />
                    </div>
                  )
                  : `${FirstName} ${LastName}`
                }
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} style={{ marginTop: '1rem' }}>
              <p className={classes.subtitle}>{t('Company name')}</p>
              <div className={classes.subtitleText}>
                {editMode
                  ? (<CustomInput
                    labelText={t('Company')}
                    id="Company"
                    formControlProps={{ fullWidth: true, className: classes.formControl }}
                    labelProps={{ style: { position: 'unset', top: 0 } }}
                    inputProps={{
                      value: formValues.Company || '',
                      onChange: inputHandler
                    }}
                  />)
                  : Company
                }
              </div>
            </GridItem>
            <GridItem xs={12} sm={4} md={4} style={{ marginTop: '1rem' }}>
              <p className={classes.subtitle}>{t('Email')}</p>
              <div className={classes.subtitleText}>{user.email}</div>
            </GridItem>

            <GridItem xs={12} sm={4} md={4} style={{ marginTop: '1rem' }}>
              <p className={classes.subtitle}>{t('Subscription info')}</p>
              <p className={classes.subtitleText}>{renderRemainingTime()}</p>
            </GridItem>
          </GridContainer>

          <Divider />

          {/* Address */}
          <GridContainer className={classes.containerWrapper}>
            <GridItem xs={12} sm={12} md={12}>
              <p className={classes.subtitle}>{t('Address')}</p>
              <div className={classes.subtitleText}>
                {editMode
                  ? (
                    <GridContainer className={classes.inputsWrapper}>
                      <GridItem xs={12}>
                        <CustomInput
                          labelText={t('City')}
                          id="City"
                          formControlProps={{ fullWidth: true, className: classes.formControl }}
                          labelProps={{ style: { position: 'unset', top: 0 } }}
                          inputProps={{
                            value: formValues.City || '',
                            onChange: inputHandler,
                            className: classes.marginRight
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12}>
                        <CustomInput
                          labelText={t('Country')}
                          id="Country"
                          formControlProps={{ fullWidth: true, className: classes.formControl }}
                          labelProps={{ style: { position: 'unset', top: 0 } }}
                          inputProps={{
                            value: formValues.Country || '',
                            onChange: inputHandler,
                            className: classes.marginRight
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12}>
                        <CustomInput
                          labelText={t('Street')}
                          id="Street"
                          formControlProps={{ fullWidth: true, className: classes.formControl }}
                          labelProps={{ style: { position: 'unset', top: 0 } }}
                          inputProps={{
                            value: formValues.Street || '',
                            onChange: inputHandler,
                            className: classes.marginRight
                          }}
                        />
                      </GridItem>

                      <GridItem xs={12}>
                        <CustomInput
                          labelText={t('ZIP')}
                          id="ZIP"
                          formControlProps={{ fullWidth: true, className: classes.formControl }}
                          labelProps={{ style: { position: 'unset', top: 0 } }}
                          inputProps={{
                            value: formValues.ZIP || '',
                            onChange: inputHandler,
                            className: classes.marginRight
                          }}
                        />
                      </GridItem>
                    </GridContainer>
                  )
                  : `
                    ${City} 
                    ${Country ? `${Country},` : ''} 
                    ${Street ? `${Street},` : ''} 
                    ${ZIP}
                  `
                }
              </div>
            </GridItem>
          </GridContainer>

          <Clearfix />
        </CardBody>
      </Card>
    </GridItem>
  );
}

export default inject('session')(observer(AboutProfileCard));
